import { cn } from "@/common/utils/css.utils";
import { useEffect, useRef, useState } from "react";

type LabeledSwitchProps<TLeft = string, TRight = string> = {
  leftLabel: string;
  leftValue: TLeft;
  rightLabel: string;
  rightValue: TRight;
  value?: TLeft | TRight;
  className?: string;
  onLeft?: (value: TLeft) => void;
  onRight?: (value: TRight) => void;
};

export const LabeledSwitch = <TLeft = string, TRight = string>({
  leftLabel,
  leftValue,
  rightLabel,
  rightValue,
  value,
  className,
  onLeft,
  onRight,
}: LabeledSwitchProps<TLeft, TRight>) => {
  const [internalValue, setInternalValue] = useState<TLeft | TRight | null>(
    value ?? null
  );

  /** We need the width of the tight button so that we know by how much to move the indicator to the left. */
  const rightButtonRef = useRef<HTMLButtonElement>(null);
  const switchLeftPosRef = useRef<number | null>(null);

  useEffect(() => {
    setInternalValue(value ?? null);
  }, [value]);

  useEffect(() => {
    const buttonElement = rightButtonRef.current;

    if (buttonElement) {
      /** Tailwind class -ml-2.5 is 10px. */
      switchLeftPosRef.current =
        buttonElement.getBoundingClientRect().width - 10;
    }
  }, []);

  const handleClick = () => {
    const newValue = internalValue === leftValue ? rightValue : leftValue;
    setInternalValue(newValue);

    if (newValue === leftValue) {
      onLeft?.(leftValue);
    }

    if (newValue === rightValue) {
      onRight?.(rightValue);
    }
  };

  const isLeftValue = internalValue === leftValue;
  const isRightValue = internalValue === rightValue;
  const { current: switchLeftPos } = switchLeftPosRef;

  const left = isLeftValue ? 0 : switchLeftPos ?? undefined;
  const right = !switchLeftPos && isRightValue ? 0 : undefined;

  return (
    <div
      className={cn(
        "relative grid w-max grid-cols-[auto_auto] rounded-full bg-flavour-100",
        className
      )}
    >
      <button
        type="button"
        className={cn(
          "absolute whitespace-nowrap rounded-full bg-brand-500 px-4 py-2 font-title text-base font-bold text-white transition-[left]",
          !internalValue && "hidden"
        )}
        style={{
          left,
          right,
        }}
        onClick={handleClick}
      >
        {internalValue === leftValue ? leftLabel : rightLabel}
      </button>
      <button
        ref={rightButtonRef}
        type="button"
        className="rounded-full px-4 py-2 font-title text-base"
        onClick={handleClick}
      >
        <span
          title={leftLabel}
          className="grid before:invisible before:h-0 before:overflow-hidden before:font-bold before:content-[attr(title)]"
        >
          {leftLabel}
        </span>
      </button>
      <button
        type="button"
        className="-ml-2.5 rounded-full px-4 py-2 font-title text-base"
        onClick={handleClick}
      >
        <span
          title={rightLabel}
          className="grid before:invisible before:h-0 before:overflow-hidden before:font-bold before:content-[attr(title)]"
        >
          {rightLabel}
        </span>
      </button>
    </div>
  );
};
