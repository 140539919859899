import { isBrowser } from "@/common/hooks/general.hooks";
import { cleanSearchParams } from "@/common/utils/router.utils";
import { CANONICAL_DOMAIN } from "@/config";
import { useRouter, Locale as RouterLocale } from "next/router";
import { Locale as GatewayLocale, Language } from "@/Apollo/schema";
import { useCallback, useEffect, useRef, useMemo } from "react";
import { objectKeys } from "@/common/utils/object.utils";

const EXCLUDE_BANNER_ROUTES = [
  "/create-ad",
  "/edit-ad",
  "/checkout",
  "/checkout2",
  "/cart",
  "/cart2",
  "/chat",
];

export const useShouldExcludeBanner = () => {
  const { asPath } = useRouter();

  return EXCLUDE_BANNER_ROUTES.some(route => asPath.includes(route));
};

export const ROUTER_LOCALE_LANGUAGE_MAP: Record<RouterLocale, Language> = {
  en: Language.En,
  nl: Language.Nl,
  de: Language.De,
  fr: Language.Fr,
};

type GatewayLocaleKeys =
  | GatewayLocale.EnUs
  | GatewayLocale.NlNl
  | GatewayLocale.DeDe
  | GatewayLocale.FrFr;

export const GATEWAY_LOCALE_LANGUAGE_MAP: Record<GatewayLocaleKeys, Language> =
  {
    [GatewayLocale.EnUs]: Language.En,
    [GatewayLocale.NlNl]: Language.Nl,
    [GatewayLocale.DeDe]: Language.De,
    [GatewayLocale.FrFr]: Language.Fr,
  };

export const useLanguage = () => {
  const { locale } = useRouter();
  return { language: ROUTER_LOCALE_LANGUAGE_MAP[locale] };
};

export const useLocalizedLanguages = (locale: RouterLocale) => {
  return useMemo(() => {
    const languageNames = new Intl.DisplayNames([locale], {
      type: "language",
    });

    return objectKeys(GATEWAY_LOCALE_LANGUAGE_MAP).map(locale => ({
      title: languageNames.of(GATEWAY_LOCALE_LANGUAGE_MAP[locale]),
      language: GATEWAY_LOCALE_LANGUAGE_MAP[locale],
      locale: locale,
    }));
  }, [locale]);
};

/**
 * Used for creating page URL with pagination params.
 *
 * @returns Functiona that generates new page URL based on the current one..
 */
export const useURLPagination = () => {
  const router = useRouter();

  const getURLString = (page: number) => {
    const url = new URL(router.asPath, `https://${CANONICAL_DOMAIN}`);

    if (page === 1) {
      url.searchParams.delete("page");
    } else {
      url.searchParams.set("page", page.toString());
    }

    // Clean search params
    cleanSearchParams(url.searchParams);

    return `${url.pathname}${url.search}`;
  };

  return { getURLString };
};

type UseRouteChangeOptions = {
  onPathnameChanged?: (pathname: string) => void;
  onRouteChangeStart?: () => void;
  onRouteChangeComplete?: () => void;
};

/**
 * Calls `on` function whenever windows's `pathname` changes.
 *
 * @param on Function to call.
 */
export const useRouteChange = ({
  onPathnameChanged,
  onRouteChangeStart,
  onRouteChangeComplete,
}: UseRouteChangeOptions) => {
  const lastPathnameRef = useRef<string | null>(null);
  const { events } = useRouter();

  const handleRouteChangeStart = useCallback(() => {
    onRouteChangeStart?.();
  }, [onRouteChangeStart]);

  const handleRoutePathnameChanged = useCallback(() => {
    const newPathname = window.location.pathname;

    if (lastPathnameRef.current !== newPathname) {
      lastPathnameRef.current = newPathname;
      onPathnameChanged?.(lastPathnameRef.current);
    }
  }, [onPathnameChanged]);

  const handleRouteChangeComplete = useCallback(() => {
    if (!isBrowser()) {
      return;
    }

    onRouteChangeComplete?.();
    handleRoutePathnameChanged();
  }, [handleRoutePathnameChanged, onRouteChangeComplete]);

  useEffect(() => {
    handleRoutePathnameChanged();

    events.on("routeChangeStart", handleRouteChangeStart);
    events.on("routeChangeComplete", handleRouteChangeComplete);

    /** Unmounted - cleanup. */
    return () => {
      events.off("routeChangeStart", handleRouteChangeStart);
      events.off("routeChangeComplete", handleRouteChangeComplete);
    };
  }, [
    events,
    handleRouteChangeComplete,
    handleRouteChangeStart,
    handleRoutePathnameChanged,
  ]);
};
