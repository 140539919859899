import { cn } from "@/common/utils/css.utils";
import { useMasterFilter } from "@/features/master-filter/components/MasterFilterProvider";
import {
  MasterFilterAll,
  MasterFilterDesigner,
} from "@/features/master-filter/contracts/master-filter.contracts";
import { LabeledSwitch } from "@/ui/LabeledSwitch";
import { useTranslation } from "next-i18next";

const MasterFilterSwitchMobile = () => {
  const { t } = useTranslation();
  const { filter, toggleAll, toggleDesigner } = useMasterFilter();

  return (
    <div className="grid grid-cols-2 md:hidden">
      <button
        type="button"
        className={cn(
          "border-b-2 pb-4",
          filter === "all" ? "border-brand-500 font-bold" : "border-transparent"
        )}
        onClick={toggleAll}
      >
        {t("common:master-filter-all-items")}
      </button>
      <button
        type="button"
        className={cn(
          "border-b-2 pb-4",
          filter === "designer"
            ? "border-brand-500 font-bold"
            : "border-transparent"
        )}
        onClick={toggleDesigner}
      >
        {t("common:master-filter-designer-items")}
      </button>
    </div>
  );
};

export const MasterFilterSwitch = () => {
  const { t } = useTranslation();
  const { filter, toggleAll, toggleDesigner } = useMasterFilter();

  return (
    <>
      <LabeledSwitch<MasterFilterAll, MasterFilterDesigner>
        leftLabel={t("common:master-filter-all-items")}
        leftValue="all"
        rightLabel={t("common:master-filter-designer-items")}
        rightValue="designer"
        value={filter}
        className="hidden md:grid"
        onLeft={toggleAll}
        onRight={toggleDesigner}
      />
      <MasterFilterSwitchMobile />
    </>
  );
};
