import HeaderAccount from "@/components/Header/HeaderAccount";
import HeaderCart from "@/components/Header/HeaderCart";
import HeaderChat from "@/components/Header/HeaderChat";
import { HeaderCreateAd } from "@/components/Header/HeaderCreateAd";
import { HeaderFavorites } from "@/components/Header/HeaderFavorites";
import HeaderLanguageSwitcher from "@/components/Header/HeaderLanguageSwitcher";
import { MegaMenu } from "@/components/mega-menu/MegaMenu";
import { HeaderSearch } from "@/components/Header/search/HeaderSearch";
import { MasterFilterSwitch } from "@/features/master-filter/components/MasterFilterSwitch";
import React from "react";
import { HeaderLogo } from "@/components/Header/HeaderLogo";
import { HeaderNotifications } from "./HeaderNotifications";
import { WhoppahFullSize } from "@/assets/icons/Logo";

type HeaderDesktopProps = {
  focused?: boolean;
};

export const HeaderDesktop = ({ focused = false }: HeaderDesktopProps) => {
  const content = focused ? (
    <>
      <HeaderLogo
        logo={<WhoppahFullSize className="mb-4 h-[45px] w-[172px]" />}
      />
      <div className="flex-1" />
      <HeaderLanguageSwitcher focused={true} />
    </>
  ) : (
    <>
      <HeaderLogo logo={<WhoppahFullSize className="h-[45px] w-[172px]" />} />
      <HeaderSearch />
      <HeaderFavorites />
      <HeaderCart />
      <HeaderAccount />
      <HeaderChat />
      <HeaderNotifications />
      <HeaderCreateAd />
    </>
  );

  const menu = focused ? null : <MegaMenu />;

  return (
    <div className="hidden flex-col space-y-6 border-b border-b-[#b0b0b0] border-opacity-40 bg-white px-6 pt-4 md:flex lg:px-12 xl:px-24">
      <div className="flex items-center space-x-8">{content}</div>
      {!focused && <MasterFilterSwitch />}
      {menu}
    </div>
  );
};
