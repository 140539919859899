import { FavoriteItemProduct } from "@/common/contracts/home.contracts";
import {
  MegaMenuItemCardData,
  MegaMenuItemData,
} from "@/common/contracts/mega-menu.contracts";
import {
  SavedSearchItemFilterItemModel,
  SavedSearchItemFilterItemModelBrand,
  SavedSearchItemFilterItemModelCategory,
  SavedSearchItemFilterItemModelMaterial,
  SavedSearchItemFilterItemModelStyle,
  SavedSearchItemFilterItemModelColor,
} from "@/common/contracts/search.contracts";
import { PartialDeep } from "type-fest";
import { Attribute } from "@/Apollo/schema";
import {
  ThreadItemProduct,
  MessageItemBid,
  ThreadItem,
  MessageItem,
  ListThreadItem,
  ListThreadItemProduct,
  MessageItemImage,
  MessageItemOrder,
} from "@/common/contracts/chat.contracts";
import { PageBlock, PageProductBlock } from "../contracts/page.contracts";
import {
  CreateProduct,
  ProductById,
  ProductByIdArtist,
} from "../contracts/create-ad.contracts";

/**
 * Type-guard that checks whether the unknown object is of certain GraphQl type.
 *
 * @param obj Unknown object that might be of certain GraphQl type.
 * @param typename String value representing GraphQL `__typename` type field.
 */
export const isGraphQLType = <T extends { __typename?: string }>(
  obj: unknown,
  typename: T["__typename"]
): obj is T => {
  return (obj as T)?.__typename === typename;
};

/**
 * Type-guard that check whether the unknown object is `FavoriteItemProduct` GraphQL type.
 *
 * @param obj Unknown object that might be `FavoriteItemProduct` GraphQL type.
 */
export const isFavoriteItemProduct = (
  obj: unknown
): obj is FavoriteItemProduct => {
  return isGraphQLType(obj, "Product");
};

/** Type-guard that check whether the "any" attribute is actually "specific" attribute.
 *
 * @param attribute Any (unknown) attribute that we want to type-check.
 * @param typename Attribute specific typename.
 */
export const isSpecificAttribute = <T extends PartialDeep<Attribute>>(
  attribute: PartialDeep<Attribute>,
  typename: NonNullable<T["__typename"]>
): attribute is T => {
  return isGraphQLType(attribute, typename);
};

/**
 * Type-guard that checks whether the {@link ThreadItem} object is actually {@link TheadItemProduct} GraphQL type.
 *
 * @param item {@link ThreadItem} object.
 */
export const isThreadItemProduct = (
  item?: ThreadItem
): item is ThreadItemProduct => {
  return isGraphQLType(item, "Product");
};

/**
 * Type-guard that checks whether the {@link ListThreadItem} object is actually {@link ListTheadItemProduct} GraphQL type.
 *
 * @param item {@link ListThreadItem} object.
 */
export const isListThreadItemProduct = (
  item?: ListThreadItem
): item is ListThreadItemProduct => {
  return isGraphQLType(item, "Product");
};

/**
 * Type-guard that checks whether the {@link MessageItem} object is actually {@link MessageItemBid} GraphQL type.
 *
 * @param item {@link MessageItem} object.
 */
export const isMessageItemBid = (
  item?: MessageItem
): item is MessageItemBid => {
  return isGraphQLType(item, "Bid");
};

/**
 * Type-guard that checks whether the {@link MessageItem} object is actually {@link MessageItemImage} GraphQL type.
 *
 * @param item {@link MessageItem} object.
 */
export const isMessageItemImage = (
  item?: MessageItem
): item is MessageItemImage => {
  return isGraphQLType(item, "Image");
};

/**
 * Type-guard that checks whether the {@link MessageItem} object is actually {@link MessageItemOrder} GraphQL type.
 *
 * @param item {@link MessageItem} object.
 */
export const isMessageItemOrder = (
  item?: MessageItem
): item is MessageItemOrder => {
  return isGraphQLType(item, "Order");
};

/**
 * Type-guard that checks whether the {@link Block} object is actually {@link ProductBlock} GraphQL type.
 *
 * @param item {@link Block} object.
 */
export const isCollection = (item?: PageBlock): item is PageProductBlock => {
  return isGraphQLType(item, "ProductBlock");
};

/**
 * Type-guard that checks whether the mega menu item is actually one of the card type items.
 *
 * @param item Mega menu item.
 */
export const isMegaMenuCardItem = (
  item: MegaMenuItemData
): item is MegaMenuItemCardData => {
  return item.type?.startsWith("card") ?? false;
};

export const isProductById = (
  product?: ProductById | CreateProduct
): product is ProductById => {
  if (!product) {
    return false;
  }

  return "status" in product;
};

export const isProductArtist = (
  attribute: unknown
): attribute is ProductByIdArtist => {
  return (attribute as { __typename?: string }).__typename === "Artist";
};

export const isBrandSavedSearchFilter = (
  model?: SavedSearchItemFilterItemModel
): model is SavedSearchItemFilterItemModelBrand => {
  return model?.__typename === "Brand";
};

export const isCategorySavedSearchFilter = (
  model?: SavedSearchItemFilterItemModel
): model is SavedSearchItemFilterItemModelCategory => {
  return model?.__typename === "Category";
};

export const isColorSavedSearchFilter = (
  model?: SavedSearchItemFilterItemModel
): model is SavedSearchItemFilterItemModelColor => {
  return model?.__typename === "Color";
};

export const isMaterialSavedSearchFilter = (
  model?: SavedSearchItemFilterItemModel
): model is SavedSearchItemFilterItemModelMaterial => {
  return model?.__typename === "Material";
};

export const isStyleSavedSearchFilter = (
  model?: SavedSearchItemFilterItemModel
): model is SavedSearchItemFilterItemModelStyle => {
  return model?.__typename === "Style";
};
