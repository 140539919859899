import { GRAPHQL_ENDPOINT } from "@/config";

export const getSearchMeta = () => {
  if (typeof window === "undefined") {
    return;
  }

  const search_meta_string = window.sessionStorage.getItem("search_meta");

  if (search_meta_string) {
    return JSON.parse(search_meta_string);
  }

  if (GRAPHQL_ENDPOINT.includes("testing")) {
    return { index: "whoppah-testing-nl" };
  } else if (GRAPHQL_ENDPOINT.includes("production")) {
    return { index: "whoppah-production-nl" };
  }

  // This should not happend, but not important enough to throw an error.
  return { index: "" };
};

export const setSearchMeta = (meta: { queryID: string; index: number }) => {
  if (typeof window === "undefined") {
    return;
  }
  const search_meta_string = JSON.stringify({
    queryID: meta.queryID,
    index: meta.index,
  });
  window.sessionStorage.setItem("search_meta", search_meta_string);
};
